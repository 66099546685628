<template>
  <div class="NioChinKong" :class="{ isLoading }">
    <section class="full hero">
      <article class="mum">
        <img :src="images.hero" />
      </article>

      <article class="main-message">
        <p class="larger message-one">
          In our hearts forever and always
        </p>
        <img :src="images.nio" class="nio" />
        <img :src="images.leaves" class="leaves" />
        <p class="message-two">12 Nov. 1949 - 30 Oct. 2020</p>
        <a href="#memories"><img :src="images.arrow" class="arrow"/></a>
      </article>
    </section>

    <section id="memories">
      <article class="left" v-in-viewport>
        <div class="gallery-wrapper" :class="{ seeAllPhotos }">
          <div class="gallery">
            <template v-for="(num, idx) in Array(31).fill(0)">
              <img
                :src="`/${String(idx+1).padStart(2, '0')}.jpg`"
                :key="idx"
                @click="openLightbox(idx)"
              />
            </template>
          </div>

          <!--
          <a class="u" href="#memories" @click="seeAllPhotos = !seeAllPhotos">
            See
            {{ !seeAllPhotos ? "More" : "Less" }}</a
          >-->
        </div>
      </article>
    </section>

    <section class="full" v-in-viewport id="footer">
      <img :src="images.leaves" class="leaves" />

      <div class="message">
        <p>Celebrating the life of Nio Chin Kong.</p>
        <p>
          Her love, laughter and generosity will be dearly missed by all.
        </p>
        <p>She will live on forever in our hearts.</p>
      </div>
      <div class="last-message">
        We love you and will miss you always.
      </div>
    </section>

    <!-- Lightbox -->
    <div v-if="showLightbox" class="lightbox" @click="closeLightbox">
      <div class="lightbox-content" @click="closeLightbox">
        <img :src="lightboxImageSrc" alt="Gallery Image" @click="closeLightbox" />
      </div>
    </div>
 
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import "scroll-behavior-polyfill";

const baseURL = "https://server.andrewlee.co/niochinkong/home/";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const images = [];

export default {
  components: {
    ImageUploader,
  },
  data: function() {
    return {
      
      baseURL: "https://server.andrewlee.co/",
      isLoading: false,
      form: {
        imageID: null,
        firstname: null,
        lastname: null,
        message: null,
      },
      images: {
        nio: "nio.svg",
        hero: "hero4.jpg",
        leaves: "leaves.png",
        arrow: "arrow.svg",
        addphoto: "addphoto.svg",
        send: "send.svg",
        bunny: "bunny.png",
      },
      seeAllPhotos: true,
      hasImage: false,
      comments: [],
      messageSent: false,
      messageSending: false,
      //totalImages: totalImages,
      imagesLoaded: 0,
      gallery: [],
      showLightbox: false,
      lightboxImageSrc: null,
    };
  },
  methods: {
    openLightbox(index) {
      this.lightboxImageSrc = `/${String(index + 1).padStart(2, '0')}.jpg`;
      this.showLightbox = true;
    },
    closeLightbox(event) {
      // Close lightbox if user clicks outside the image
      if (event.target === event.currentTarget) {
        this.showLightbox = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");

$cream: #ebeae6;
$brown: #684e14;
$fadebrown: #cac2b1;
$veryfadebrown: #dedbd1;
$breakpoint: 1025px;

:root {
  --font-size: 13px;
  --margin: 32px;
  @media screen and (max-width: $breakpoint) {
    --margin: 24px;
    --font-size: 12px;
  }
}

.larger {
  font-size: 1.1rem;
}

.messageSending {
  opacity: 0.5;
  pointer-events: none;
}

h1,
h2,
h3 {
  font-weight: normal;
}

h2 {
  margin-bottom: var(--margin);
}

html,
body {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Libre Baskerville", serif;
  color: $brown;
  background: $cream;
  font-size: var(--font-size);
  line-height: 166%;
  min-width: 375px;
}

* {
  font-family: inherit;
}

u,
.u {
  color: $brown;
  text-decoration: none;
  position: relative;
  text-decoration: none;
  border-bottom: 1.5px $brown solid;
  padding-bottom: 4px;
}

* {
  max-width: 100%;
  box-sizing: border-box;
}

section.full {
  min-height: 768px;
  height: 100vh;

  @media screen and (max-width: $breakpoint) {
    min-height: auto;
  }
}

body.loading {
  overflow: hidden;
  cursor: wait;
}

.NioChinKong {
  transition: 1s;
  opacity: 0;

  .hero {
    display: flex;

    @media screen and (max-width: $breakpoint) {
      flex-direction: column;
      article {
        width: 100% !important;
        height: 50% !important;
        min-height: 320px;
        &.mum {
          order: 2;
        }
        &.mum img {
          width: calc(100% - var(--margin) * 2);
        }

        &.main-message {
          text-align: center;
          padding: var(--margin) 0;
          p {
            position: relative;
            z-index: 5;
          }

          .arrow {
            bottom: -48px;
          }

          .nio {
            max-width: 90%;
          }
          .leaves {
            width: 128px;
            object-fit: contain;
            max-height: calc(100% - 32px);
            margin: -16px;
          }
        }
      }
    }

    > article {
      width: 50%;
      height: 100%;
    }

    .mum img {
      margin: var(--margin);
      margin-right: 0;
      width: calc(100% - var(--margin));
      height: calc(100% - var(--margin) * 2);
      object-position: top left;
      object-fit: cover;
    }

    .main-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .larger {
        @media screen and (max-width: $breakpoint) {
          margin-top: 36px;
        }
      }

      .leaves {
        width: 168px;
      }

      .nio {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }

      .nio {
        width: 665px;
        max-width: calc(100% - 75px);
      }

      .arrow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: var(--margin);
        cursor: pointer;
      }
    }
  }

  .nio,
  .leaves,
  p,
  .arrow {
    transition: opacity 1s, transform 1s;
    opacity: 0;
    transform: translateY(12px);
  }

  &:not(.isLoading) {
    opacity: 1;
    .leaves,
    .nio,
    p,
    .arrow {
      opacity: 1;
      transform: translateY(0px);
    }
    .message-one {
      transition-delay: 1.5s;
    }
    .leaves {
      transition-delay: 0.5s;
    }
    .nio {
      transition-delay: 1s;
    }
    .message-two {
      transition-delay: 2s;
    }

    .arrow {
      transition-delay: 2.5s;
    }
  }
}

.left,
.right,
#footer {
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(12px);
  opacity: 0;
  transition-delay: 0.5s;

  &.in-viewport:not(.loading) {
    opacity: 1;
    transform: translateY(0);
  }
}

.right {
  transition-delay: 1s;
}

#memories {
  min-height: 100vh;
  display: flex;
  padding-right: 0;

  @media screen and (max-width: $breakpoint) {
    flex-direction: column;
  }

  > article {
    padding: var(--margin);
    width: calc(100% - var(--margin));

    @media screen and (max-width: $breakpoint) {
      height: auto;
      width: calc(100% - var(--margin));
    }

    &.left {
      padding-right: 0;
      position: relative;

      u {
        position: relative;
        top: -12px;
      }
    }

    &.right {
      @media screen and (min-width: $breakpoint) {
        padding: calc(var(--margin) * 2);
      }

      @media screen and (max-width: $breakpoint) {
        width: 100%;
      }
      padding-top: var(--margin);
    }

    .form-wrapper {
      display: flex;
      justify-content: center;
      padding-bottom: 70px;
      border-bottom: 1px $fadebrown solid;
    }

    form {
      display: inline-flex;
      width: 100%;

      .bunny {
        flex-shrink: 0;
        width: 100px;
        margin-left: 48px;
        margin-top: -48px;
        margin-bottom: -32px;
        object-fit: contain;
        object-position: bottom center;

        @media screen and (max-width: $breakpoint) {
          margin-left: 12px;
          width: 62px;
        }
      }

      button {
        &[disabled] {
          opacity: 0.5;
        }
        &:focus {
          outline: none;
        }
        appearance: none;
        border: none;
        background: none;
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 4px 0;
        transform: translate(-12px, 32px);
        color: $brown;
        border-bottom: 1px $fadebrown solid;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-left: 12px;
        }

        &:hover {
          border-color: $brown;
        }
      }

      fieldset {
        flex-grow: 1;
        position: relative;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        justify-content: space-between;
        margin-left: 12px;
        .textarea {
          width: 100%;
          min-height: 64px !important;
        }

        .textarea[contenteditable]:empty::before {
          visibility: visible;
        }

        .textarea[contenteditable]::before {
          position: absolute;
          content: "Your Message";
          color: $brown;
          visibility: hidden;
          pointer-events: none;
        }
        input {
          width: 40%;
          min-width: 150px;
          flex-grow: 1;
        }

        .textarea,
        input {
          appearance: none;
          margin: 0 12px;
          background: none;
          border: none;
          border-bottom: 1px $fadebrown solid;
          font-size: 12px;
          margin-bottom: 16px;
          padding-bottom: 9px;
          min-height: 24px;
          overflow: hidden;
          resize: none;
          color: $brown;
          border-radius: 0;
          padding: 6px 2px;

          &::placeholder {
            color: $brown;
          }

          &:focus {
            outline: none;
            border-bottom: 1px $brown solid;
          }
        }
      }

      .image-uploader-wrapper {
        transition: 0.2s;
        flex-shrink: 0;
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        border: 1px $brown solid;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: $veryfadebrown;
        }

        &.has-image {
          border: none;
        }
      }

      .img-preview {
        width: 100%;
        height: 100%;
        border: 1px $brown solid;
        object-fit: cover;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        z-index: 2;
        width: 64px;
        height: 64px;
        border-radius: 100%;
      }

      label {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        figure {
          position: absolute;
        }
      }

      #fileInput {
        display: none;
      }
    }
  }
}

.comments {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.comment {
  width: 100%;
  display: flex;

  @media screen and (min-width: $breakpoint) {
    &:nth-child(2n) {
      //margin-left: 32px;
    }
  }

  .meta {
    padding-top: 12px;
    display: flex;
    .created {
      opacity: 0.5;
      font-size: 10px;
      margin-left: 16px;
    }
  }
  padding-bottom: 24px;
  margin-top: 24px;
  position: relative;

  &:after {
    content: "";
    border-bottom: 1px $brown solid;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.25;
  }
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s;
}

.gallery-wrapper,
.comments-wrapper {
  position: sticky;
  top: var(--margin);
}

.gallery-wrapper {
  @media screen and (max-width: $breakpoint) {
    text-align: center;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--margin);
  overflow: hidden;
  height: calc(100vh - var(--margin) * 3);
  left: var(--margin);
  width: 100%;

  img {
    transition: opacity 0.2s, filter 0.2s, transform 0.2s;
    cursor: pointer;
    flex-grow: 1;
    height: 20%;
    min-height: 150px;
    @media screen and (max-width: $breakpoint) {
      height: 14.28%;
      min-height: 100px;
    }
    object-fit: cover;
    object-position: 50% 25%;
    &:hover {
      z-index: 2;
      position: relative;
      //filter: brightness(0.9);
      opacity: 0.85;
    }
  }
}

.seeAllPhotos .gallery {
  height: auto;
  img {
    height: 14vh;
  }

  &::after {
    content: "";
    flex-grow: 1000000000;
  }
}

u {
  cursor: pointer;
}

#footer {
  padding: var(--margin);
  position: relative;
  text-align: center;

  .message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
  }

  .last-message {
    position: absolute;
    left: 50%;
    bottom: var(--margin);
    transform: translate(-50%, 0%);
  }

  p {
    font-size: 16px;
  }

  .leaves {
    width: 168px;
    position: absolute;
    left: 50%;
    top: calc(50% - 12px);
    transform: translate(-50%, -50%);

    @media screen and (max-width: $breakpoint) {
      width: 180px;
      opacity: 0.8;
    }
  }
}

/* Lightbox Styles */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure lightbox is on top */
}

.lightbox-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.lightbox-content img {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
