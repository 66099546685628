import Home from './components/Home'
import Vue from 'vue/dist/vue.esm.js'
import VueRouter from 'vue-router'
import inViewportDirective from 'vue-in-viewport-directive'

Vue.directive('in-viewport', inViewportDirective)
Vue.use(VueRouter);

const routes = [
  { path: '*', redirect: '/'},
  { path: '/', component: Home },
]

const router = new VueRouter({
  routes
})

const app = new Vue({
  router
}).$mount('#app')